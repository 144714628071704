/**
 * @copyright Sister Software. All rights reserved.
 * @author Teffen Ellis, et al.
 * @license
 * See LICENSE file in the project root for full license information.
 */
import { ModelFamilyStore } from './models/mod.mjs';
import { encode } from './tokenizer/mod.mjs';
export const estimateCost = (modelInput, ...inputs) => {
    const modelFamily = ModelFamilyStore.get(modelInput);
    const encodedResults = inputs.map((input) => encode(input));
    const tokenCount = encodedResults.reduce((acc, result) => acc + result.tokens.length, 0);
    // Remember that pricing is per 1000 tokens
    const pricedUnits = tokenCount / 1000;
    const result = {};
    for (const [pricingType, pricePer] of Object.entries(modelFamily.pricing)) {
        const price = typeof pricePer === 'number' ? pricePer * pricedUnits : null;
        result[pricingType] = price;
    }
    return result;
};
